jQuery(document).ready(function ($) { 

    //menu
    var $hamburger = $(".hamburger");
    $hamburger.on("click", function () {
        $hamburger.toggleClass("is-active"); 
        $("html,body").toggleClass("menu-open");
    });

    //add lightbox to images
    //    $('.lightbox').modaal({
    //		type: 'image'
    //	});

    //    $('.lightbox').lightGallery({
    //        selector: 'this'
    //    });
    //    
    //    $('.gallery').lightGallery({
    //        thumbnail:true
    //    }); 
    
    $('a[href]').filter(function () {
        return this.href && this.href.match(/\.(?:jpe?g|gif|bmp|a?png)$/i); 
    }).addClass('lightbox');
    
    //add lightbox to images
    //$('.lightbox').swipebox(); 
    
    //close menu with an outside click (basically anywhere on .site-content)
    $('.site-content').on('click', function() {
        $('.sub-menu').removeClass('toggled-on');
    }); 
    
    /*! 
    ♡♡♡♡♡♡♡♡♡♡♡
    ♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥
    More accessible gravity forms - see forms/_fields.scss for the styles
    ♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥
    ♡♡♡♡♡♡♡♡♡♡♡
    */
    
    //wrap label with a span
    $( ".gfield_label" ).wrapInner( "<span class='label-content'></span>");
    
    //Add .sunshine class to each field in the forms before using this 
    //This basically adds an active class to a focused in input field 
    $( ".gfield input, .gfield textarea" ).focusin(function() {
        $(this).parents( ".gfield" ).addClass("active");
    });
    //Check if the field is filled - if it is the active class will stay, if not the active class will revert to its non active state
    $( ".gfield input, .gfield textarea" ).focusout(function() {
//        console.log($(this).val().length);
        
        if ($(this).val().length == 0 ) {
            $(this).parents( ".gfield" ).removeClass("active");
        }
        
    });
    
    $('.slider').slick({
        accessibility: true,
        autoplay: true,
        draggable: true,
        infinite: true,
        pauseOnHover: false,
        swipe: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        centerMode: true,
        variableWidth: true,
        arrows: false
    });
    
});